import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout, Container } from 'layout';
import BreadCrumbs from 'common/BreadCrumbs';
import ArticleBody from 'components/ArticleBody';
import ArticleReferences from 'components/ArticleReferences';
import TargetingSection from 'components/TargetingSection';
import FeaturedProducts from 'components/FeaturedProducts';
import FeaturedArticles from 'components/FeaturedArticles';
import HeroBanner from 'components/HeroBanner';

import { isNotEmptyArray } from 'utils/array';

import { IPropsArticleSymptomsPage } from './models';
import './ArticleSymptomsPage.scss';

// activeColor is a part of the future ProductFamily functionality
const activeColor = '#FDBD21';

const ArticleSymptomsPage: FC<IPropsArticleSymptomsPage> = ({
  pageContext: { breadcrumbs, lang },
  data: {
    articleSymptoms: {
      seo,
      defaultCompositions,
      articleHeroBanner,
      articleTableOfContentsTitle,
      articleBody,
      articleReferenceSection,
      targetingSection,
      featuredArticlesList,
      featuredProductsList,
      selectedProductsIds,
      createDate,
      updateDate,
    },
    featuredArticlesByLink,
    featuredArticlesByTag,
    featuredArticlesSymptomsByLink,
    featuredArticlesSymptomsByTag,
    featuredArticlesAboutStrepsilsByLink,
    featuredArticlesAboutStrepsilsByTag,
    featuredProductsByLink,
    featuredProductsByTag,
    featuredProductsByLinkInBody,
    featuredProductsByTagInBody,
    articleSettings,
  },
}) => (
  <Layout
    defaultCompositions={defaultCompositions}
    seo={seo}
    anefpBlockCustomization={{ isHideBlock: true }}
    className="article-symptoms-page"
  >
    <Container>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
    </Container>
    {articleHeroBanner ? (
      <HeroBanner heroBanner={articleHeroBanner} showTitleOnTop hideOnMobile />
    ) : null}
    <Container>
      <ArticleBody
        articleBody={articleBody}
        defaultCompositions={defaultCompositions}
        lang={lang}
        featuredProductsByLinkInBody={featuredProductsByLinkInBody}
        featuredProductsByTagInBody={featuredProductsByTagInBody}
        articleTableOfContentsTitle={articleTableOfContentsTitle}
        selectedProductsIds={selectedProductsIds}
        articleSettings={articleSettings}
        createDate={createDate}
        updateDate={updateDate}
      />
    </Container>
    {isNotEmptyArray(featuredArticlesList) ? (
      <FeaturedArticles
        featuredArticlesList={featuredArticlesList}
        featuredArticlesByLink={[
          ...featuredArticlesAboutStrepsilsByLink.nodes,
          ...featuredArticlesSymptomsByLink.nodes,
          ...featuredArticlesByLink.nodes,
        ]}
        featuredArticlesByTag={[
          ...featuredArticlesAboutStrepsilsByTag.nodes,
          ...featuredArticlesSymptomsByTag.nodes,
          ...featuredArticlesByTag.nodes,
        ]}
        dir={defaultCompositions.siteSettings.dir[0]}
        ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
        ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
      />
    ) : null}
    {isNotEmptyArray(featuredProductsList) ? (
      <FeaturedProducts
        featuredProducts={featuredProductsList}
        featuredProductsByLink={featuredProductsByLink.nodes}
        featuredProductsByTag={featuredProductsByTag.nodes}
        productsOrder={featuredProductsList?.[0]?.properties?.selectedProductsIds || []}
        dir={defaultCompositions.siteSettings.dir[0]}
        ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
        ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
        activeColor={activeColor}
      />
    ) : null}
    {isNotEmptyArray(targetingSection) ? (
      <Container>
        <TargetingSection
          targetingSection={targetingSection}
          ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
          ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
          productQuizData={defaultCompositions.productQuiz}
          lang={lang}
        />
      </Container>
    ) : null}
    {articleReferenceSection[0] ? (
      <ArticleReferences articleReferenceSection={articleReferenceSection[0]} />
    ) : null}
  </Layout>
);

export const query = graphql`
  query ArticleSymptomsPage(
    $link: String = ""
    $lang: String!
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
    $selectedProductsLinks: [String]
    $selectedProductTag: Int
    $featuredProductsURLsInBody: [String]
    $featuredProductsTagInBody: Int
  ) {
    articleSymptoms(link: { eq: $link }) {
      createDate
      updateDate
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      articleHeroBanner {
        ...FragmentHeroBanner
      }
      articleTableOfContentsTitle
      articleBody {
        ...FragmentArticleBody
      }
      articleReferenceSection {
        ...FragmentArticleReference
      }
      targetingSection {
        ...FragmentTargetingSection
      }
      featuredArticlesList {
        ...FragmentFeaturedArticles
      }
      featuredProductsList {
        ...FragmentFeaturedProducts
      }
      selectedProductsIds: featuredProductsURLsInBody {
        id
      }
    }
    featuredArticlesSymptomsByLink: allArticleSymptoms(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleSymptomsCardRelated
      }
    }
    featuredArticlesSymptomsByTag: allArticleSymptoms(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleSymptomsCardRelated
      }
    }
    featuredArticlesByLink: allArticle(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredArticlesByTag: allArticle(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredArticlesAboutStrepsilsByLink: allArticleAboutStrepsils(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleAboutStrepsilsCardRelated
      }
    }
    featuredArticlesAboutStrepsilsByTag: allArticleAboutStrepsils(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleAboutStrepsilsCardRelated
      }
    }
    featuredProductsByLink: allProduct(
      filter: { link: { in: $selectedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredProductsByTag: allProduct(
      filter: { tags: { elemMatch: { id: { eq: $selectedProductTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredProductsByLinkInBody: allProduct(
      filter: { link: { in: $featuredProductsURLsInBody }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredProductsByTagInBody: allProduct(
      filter: {
        tags: { elemMatch: { id: { eq: $featuredProductsTagInBody } } }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    articleSettings(lang: { eq: $lang }) {
      ...FragmentArticleSettings
    }
  }
`;

export default ArticleSymptomsPage;
